<template>
  <section class="solucoes">
    <h1 class="text-center fs-1" style="padding-top: 50px;">Por que escolher a plataforma Inzapbrasil?</h1>
    <div class="container" style="margin-top: 40px">
      <div class="solucoes-grid">
        <div class="solucao">
          <img src="../../assets/img/homeSolucoes/telefone.png" alt="" />
          <h1 class="bold fs-4 mt-4 text-left">VÁRIOS ATENDENTES</h1>
          <p class="mt-3 text-left">
            Com apenas um número seu empreendimento terá vários atendentes.
            Alavanque suas vendas
          </p>
        </div>
        <div class="solucao">
          <img src="../../assets/img/homeSolucoes/raio.png" alt="" />
          <h1 class="bold fs-4 mt-4 text-left">PRODUTIVIDADE</h1>
          <p class="mt-3 text-left">
            Com apenas um número seu empreendimento terá vários atendentes.
            Alavanque suas vendas
          </p>
        </div>
        <div class="solucao">
          <img
            src="../../assets/img/homeSolucoes/simbolo-de-cadeado-para-interface-de-seguranca.png"
            alt=""
          />
          <h1 class="bold fs-4 mt-4 text-left">SEGURANÇA DE DADOS</h1>
          <p class="mt-3 text-left">
            Com apenas um número seu empreendimento terá vários atendentes.
            Alavanque suas vendas
          </p>
        </div>
        <div class="solucao">
          <img src="../../assets/img/homeSolucoes/acordo.png" alt="" />
          <h1 class="bold fs-4 mt-4 text-left">COLABORE COM FACILIDADE</h1>
          <p class="mt-3 text-left">
            Com apenas um número seu empreendimento terá vários atendentes.
            Alavanque suas vendas
          </p>
        </div>
        <div class="solucao">
          <img src="../../assets/img/homeSolucoes/celular.png" alt="" />
          <h1 class="bold fs-4 mt-4 text-left">TRABALHE ONDE QUISER</h1>
          <p class="mt-3 text-left">
            Com apenas um número seu empreendimento terá vários atendentes.
            Alavanque suas vendas
          </p>
        </div>
        <div class="solucao">
          <img
            src="../../assets/img/homeSolucoes/programacao-da-web.png"
            alt=""
          />
          <h1 class="bold fs-4 mt-4 text-left">PLATAFORMA ÚNICA</h1>
          <p class="mt-3 text-left">
            Com apenas um número seu empreendimento terá vários atendentes.
            Alavanque suas vendas
          </p>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "BannerSolucoes",
};
</script>



<style scoped>
.solucoes{
  margin-bottom: 50px;
}

.solucoes-grid {
  max-width: 1080px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
}

.solucao {
  padding: 10px;
}

.solucao p {
  font-size: 1.2rem;
}
</style>