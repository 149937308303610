<template>
  <div class="container-fluid h-custom">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-md-9 col-lg-6 col-xl-5">
        <img
          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
          class="img-fluid"
          alt="Sample image"
        />
      </div>
      <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
        <form>
          <div class="form-outline mb-4">
            <label class="form-label">guilher me</label>
            <input
              type="email"
              class="form-control form-control-lg"
              placeholder="Enter login"
            />
          </div>
          <div class="form-outline mb-3">
            <label class="form-label">Senha</label>
            <input
              type="password"
              class="form-control form-control-lg"
              placeholder="Enter password"
            />
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <router-link to="/forgot">Forgot password?</router-link>
          </div>

          <div class="text-center text-lg-start mt-4 pt-2">
            <button
              type="submit"
              class="btn btn-primary btn-lg"
              style="padding-left: 2.5rem; padding-right: 2.5rem"
            >
              <span class="material-icons"> face </span>
              Login
              <span class="material-icons"> account_circle </span>
            </button>
            <p class="small fw-bold mt-2 pt-1 mb-0">
              Don't have an account?
              <router-link to="/register">Register</router-link>
            </p>
          </div>
        </form>
      </div>
    </div>

    <router-link to="/">
      <button class="btn btn-primary">Voltar</button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "BannerLogin",
};
</script>

<style>
</style>