<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-light bg-light p-2 fi">
      <div class="container-fluid">
        <router-link to="/" class="navbar-brand">
          <img src="../../assets/img/inzap.png" alt="inzapbrasil" width="142" />
        </router-link>
        <div
          :class="situacao ? 'active' : ''"
          class="menu_hamburguer navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="responsivo()"
        >
          <div class="one"></div>
          <div class="two"></div>
          <div class="three"></div>
        </div>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item p-2">
              <a class="nav-link text-dark fs-5" href="#">Soluções</a>
            </li>
            <li class="nav-item p-2">
              <a class="nav-link text-dark fs-5" href="#">Preços</a>
            </li>
            <li class="nav-item p-2">
              <a class="nav-link text-dark fs-5" href="#">Contato</a>
            </li>
          </ul>
          <div class="d-flex align-items-center flex-column flex-lg-row gap-3">
            <router-link to="/login">
              <button class="fs-5 borda p-10 bg-light rounded">Entrar</button>
            </router-link>
            <div class="bg-laranja full text-center p-10 rounded">
              <span class="fs-5 text p-2 color-cinza"> Comece agora </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "HeaderMenu",
  data() {
    return {
      situacao: false,
    };
  },
  methods: {
    responsivo() {
      this.situacao = !this.situacao;
    },
  },
};
</script>


<style scoped>
.navbar-toggler {
  border: 0 !important;
}

.menu_hamburguer {
  width: 40px;
  height: 30px;
  margin-right: 20px;
}
.menu_hamburguer div {
  background-color: #111;
  height: 4px;
  width: 40px;
  margin: 5px auto;
  opacity: 1;
  transition-duration: 0.3s;
}

.menu_hamburguer.active .one {
  transform: rotate(45deg) translate(7px, 7px);
}

.menu_hamburguer.active .two {
  opacity: 0;
}

.menu_hamburguer.active .three {
  transform: rotate(-45deg) translate(5px, -7px);
}

.navbar {
  font-family: "Fira Sans", sans-serif;
}

.bold {
  font-weight: bold;
}

.navbar-nav button {
  width: 100%;
}
.full {
  width: 100%;
}

@media (max-width: 995px) {
  .borda {
    border: 2px solid #0065ff;
    width: 100%;
  }
}
</style>