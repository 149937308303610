<template>
  <section class="container">
    <BannerLogin />
  </section>
</template>

<script>
import BannerLogin from "@/components/login/BannerLogin.vue";


export default {
  name: "LoginPage",
  components: {

    BannerLogin,

  },
};
</script>

<style>
</style>

