<template>
  <div class="caixa">
    <HeaderMenu />
    <BannerHome />
    <BannerSolucoes />
    <BannerPlanos />
    <section>
      <h1>cavalo</h1>
    </section>
    <BannerFooter/>
  </div>
</template>


<script>
import HeaderMenu from "@/components/index/HeaderMenu.vue";
import BannerHome from "@/components/index/BannerHome.vue";
import BannerSolucoes from "@/components/index/BannerSolucoes.vue";
import BannerPlanos from "@/components/index/BannerPlanos.vue";
import BannerFooter from "@/components/index/BannerFooter.vue";

export default {
  name: "HomePage",
  components: { HeaderMenu, BannerHome, BannerSolucoes, BannerPlanos, BannerFooter },
};
</script>


<style scoped>

.prices{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}
.prices h1 {
    font-size: 1rem;
    background: red;
}
</style>