<template>
  <section class="precos mt-2">
    <h1 class="text-center bg-laranja text-light fw-bold p-3">
      Escolha o inzap do seu jeito.
    </h1>

    <div class="container mt-5">
      <div class="planos">
        <div class="plano">
          <h2>STANDARD</h2>
          <h3 class="card-title pricing-card-title">
            $15<small class="text-muted fw-light">/mo</small>
          </h3>
          <p class="mt-3">
            Para pessoas ou equipes que procuram organizar qualquer projeto.
          </p>

          <h4 class="fs-5 mt-4">INCLUÍDO NO PLANO STANDARD:</h4>
          <ul class="list-unstyled mt-3 mb-4">
            <li>
              <span class="material-icons verde"> done </span> 10 users included
            </li>
            <li>
              <span class="material-icons verde"> done </span> 2 GB of storage
            </li>
            <li>
              <span class="material-icons verde"> done </span> Email support
            </li>
            <li>
              <span class="material-icons verde"> done </span> Help center
              access
            </li>
          </ul>
          <button
            class="p-3 border-laranja fs-5"
            style="background: transparent"
          >
            Inscreva-se agora
          </button>
        </div>
        <div class="plano">
          <h2>PREMIUM</h2>
          <h3 class="card-title pricing-card-title">
            $15<small class="text-muted fw-light">/mo</small>
          </h3>
          <p class="mt-3">
            Para pessoas ou equipes que procuram organizar qualquer projeto.
          </p>
          <h4 class="fs-5 mt-4">TUDO NO PLANO STANDARD, ALÉM DE:</h4>
          <ul class="list-unstyled mt-3 mb-4">
            <li>
              <span class="material-icons verde"> done </span> 20 users included
            </li>
            <li>
              <span class="material-icons verde"> done </span> 10 GB of storage
            </li>
            <li>
              <span class="material-icons verde"> done </span> Priority email
              support
            </li>
            <li>
              <span class="material-icons verde"> done </span> Help center
              access
            </li>
          </ul>
          <button class="p-3 bg-laranja fs-5">Inscreva-se agora</button>
        </div>
        <div class="plano">
          <h2>ENTERPRISE</h2>
          <h3 class="card-title pricing-card-title">
            $15<small class="text-muted fw-light">/mo</small>
          </h3>
          <p class="mt-3">
            Para pessoas ou equipes que procuram organizar qualquer projeto.
          </p>
          <h4 class="fs-5 mt-4">TODOS OS RECURSOS DO PREMIUM E MAIS:</h4>
          <ul class="list-unstyled mt-3 mb-4">
            <li>
              <span class="material-icons verde"> done </span> 30 users included
            </li>
            <li>
              <span class="material-icons verde"> done </span> 15 GB of storage
            </li>
            <li>
              <span class="material-icons verde"> done </span> Phone and email
              support
            </li>
            <li>
              <span class="material-icons verde"> done </span> Help center
              access
            </li>
          </ul>
          <button
            class="p-3 border-laranja fs-5"
            style="background: transparent"
          >
            Inscreva-se agora
          </button>
        </div>
      </div>
    </div>

    <div class="container">
        <BannerTablePlans/>
    </div>
  </section>
</template>




<script>
import BannerTablePlans from './BannerTablePlans.vue';
export default {
    name: "BannerPlanos",
    components: { BannerTablePlans }
}
</script>






<style scoped>
.verde {
  color: #36b37e;
}

.precos {
  background: #fbfaff;
  padding-bottom: 10px;
}
.planos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.planos .plano {
  border: 1px solid #cdcdcd;
  padding: 20px;
}

.plano:nth-child(2) {
  border: 2px solid #f58634;
}

button {
  font-family: "Fira Sans", sans-serif;
}

@media (max-width: 1000px) {
  .planos {
    gap: 20px;
    grid-template-columns: 1fr;
  }
}
</style>