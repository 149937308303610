import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '../views/login/LoginPage.vue'
import HomePage from '../views/index/HomePage.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
