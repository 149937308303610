<template>
  <section class="home">
    <div class="container-fluid">
      <div
        class="
          row
          d-flex
          flex-column-reverse flex-md-row
          justify-content-around
        "
      >
        <div class="col-md-6 d-flex flex-column justify-content-center">
          <h1 class="fw-bold f-36">
            A Inzap ajuda sua empresa avançar no trabalho.
          </h1>
          <p style="font-size: 18px">
            Envie mensagens, promoções, marketing, etc. de uma só vez a todos os
            seus clientes e contatos. Atenda seu público alvo ao mesmo tempo e
            com apenas um número.
          </p>

          <div class="btn-div">
            <button class="bg-laranja color-cinza rounded mt-2 fs-4 fw-bold">
              Obtenha a Inzap
            </button>
          </div>
        </div>
        <div class="col-md-5">
          <img
            src="../../assets/Mobile Marketing-bro.svg"
            alt="marketing"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "BannerHome",
};
</script>


<style scoped>
.home {
  padding: 30px;
  background-color: #eeebff;
}
.f-36 {
  font-size: 36px;
}
.img-home {
  width: 90%;
}
.mr-60 {
  margin-right: 60px;
}
.btn-div button {
  padding: 10px 20px;
}
@media (max-width: 750px) {
  .btn-div button {
    width: 100%;
  }
}
</style>